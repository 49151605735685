<template>
  <div class="w-full">
    <div class="w-full">
      <div class="w-full flex items-center">
        <vs-checkbox
          v-model="acceptTermsAndConditions"
          style="margin-top: 0 !important"
          class="mt-6 inline-flex mt-0"
          @change="validateTermsAndConditions()">
        </vs-checkbox>
        <span v-html="$t('IAgreeWithTermsAndConditions')"/>
      </div>
      <div
        v-if="!hideValidationMsg"
        class="w-full">
        <vs-input
          :value="acceptTermsAndConditions ? 'ok' : ''"
          type="hidden"
          class="w-full"
          :name="$t('$General.TermsAndConditions')"
          v-validate="'required'"
          :danger="errors.has($t('$General.TermsAndConditions'))"
          :danger-text="errors.first($t('$General.TermsAndConditions'))"/>
      </div>
    </div>

    <div class="w-full mt-5">
      <div class="w-full flex items-center">
        <vs-checkbox
          v-model="acceptReceiveNewsAndUpdates"
          style="margin-top: 0 !important"
          class="mt-6 inline-flex mt-0">
        </vs-checkbox>
        <span>
          {{ $t('IAgreeWithReceiveNewsAndUpdates') }}
        </span>
      </div>
    </div>

    <slot
      name="buttons-wrapper"
      v-bind:termsAndConditionsValid="formIsValid">
      <vs-button
        v-if="confirmButton"
        class="mt-base"
        @click="redirectSignUpGoogle()">
        <slot>
          {{ $t('$Auth.SignUp') }}
        </slot>
      </vs-button>
    </slot>
  </div>
</template>

<script>
import configApi from '@/api/config';

/**
 * Component to review terms and conditions
 *
 * @module views/pages/auth/components/ReviewTermsAndConditions
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} confirmButton - indicate if show or no confirm button
 * @vue-prop {boolean} [hideValidationMsg=false] - indicate if show or no validation msg
 * @vue-data {boolean} acceptTermsAndConditions - indicate if terms and conditions was accepted
 * @vue-data {boolean} acceptReceiveNewsAndUpdates -
 * indicate if receive news and updates was accepted
 * @vue-event {void} validateTermsAndConditions - validate terms and condition checkbox
 * @vue-event {void} formIsValid - indicate if the form is valid
 * @vue-event {void} redirectSignUpGoogle - redirect to sign up with google
 */
export default {
  name: 'ReviewTermsAndConditions',
  i18n: {
    messages: {
      en: {
        IAgreeWithTermsAndConditions: 'I agree to the <a class="link-contextual inline-flex" href="https://grapesend.com.au/legal#terms-and-conditions" target="_blank">Terms & Conditions</a>.',
        IAgreeWithReceiveNewsAndUpdates: 'Send me occasional product updates and announcements.',
      },
    },
  },
  props: {
    confirmButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideValidationMsg: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      acceptTermsAndConditions: false,
      acceptReceiveNewsAndUpdates: false,
    };
  },
  watch: {
    acceptTermsAndConditions() {
      this.$emit('termsAndConditions', this.acceptTermsAndConditions);
    },
    acceptReceiveNewsAndUpdates() {
      this.$emit('newsAndUpdates', this.acceptReceiveNewsAndUpdates);
    },
  },
  methods: {
    validateTermsAndConditions() {
      this.$nextTick(() => this.$validator.validate(this.$t('$General.TermsAndConditions')));
    },
    async formIsValid() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
      }

      return formIsValid;
    },
    async redirectSignUpGoogle() {
      const valid = await this.formIsValid();

      if (valid) {
        window.location.href = `${configApi.url}/auth/google/${this.acceptTermsAndConditions}/${this.acceptReceiveNewsAndUpdates}`;
      }
    },
  },
};
</script>
